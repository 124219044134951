$primary-color: #10183d;
$text-white: #ffffff;

body {
    font-family: "Inter", sans-serif !important;
}

.bg-appcolor {
    background-color: $primary-color !important;
    color: $text-white;
}

.content {
    height: calc(100vh - 110.38px);
    overflow-y: auto;
}

.content {
    scroll-behavior: smooth;
}

.terms ul > li {
    margin-left: 35px;
}

.cp {
    cursor: pointer !important;
}
